import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import {
  Box,
  FullScreenContainer,
  SiteName,
} from "../../helpers/styledComponents"
import theme from "../../helpers/theme"

const NavLink = styled(Link)(props => ({
  margin: "0 6px",
  textDecoration: "none",
  letterSpacing: "1.75px",
  textTransform: "uppercase",
  fontSize: "0.65rem",
  color: props.color || theme.colors.black,
  "&:last-child": {
    marginRight: 0,
  },
  [theme.mediaQueries[0]]: {
    margin: "0 20px",
    fontSize: "0.75rem",
  },
}))

const Header = props => {
  return (
    <header
      css={{
        height: theme.header.height,
        width: "100%",
        position: "absolute",
        top: 0,
        zIndex: 10,
        backgroundColor: props.backgroundColor || theme.colors.white,
      }}
    >
      <FullScreenContainer>
        <Box
          height="80px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <SiteName color={props.textColor} to="/">
              Jeremy Hsu
            </SiteName>
          </Box>
          <Box>
            <nav>
              <NavLink color={props.textColor} to="/about">
                About
              </NavLink>
              <NavLink color={props.textColor} to="/work">
                Work
              </NavLink>
              <NavLink color={props.textColor} to="/contact">
                Contact
              </NavLink>
            </nav>
          </Box>
        </Box>
      </FullScreenContainer>
    </header>
  )
}

export default Header
