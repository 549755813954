import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import theme from "./theme"

import {
  background,
  borderRadius,
  border,
  color,
  flexbox,
  colorStyle,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  layout,
  letterSpacing,
  lineHeight,
  position,
  space,
  size,
  textStyle,
  typography,
} from "styled-system"

export const Box = styled.div`
  ${background}
  ${borderRadius}
  ${border}
  ${color}
  ${colorStyle}
  ${flexbox}
  ${fontFamily}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${fontWeight}
  ${grid}
  ${letterSpacing}
  ${layout}
  ${lineHeight}
  ${position}
  ${size}
  ${space}
  ${textStyle}
  ${typography}
`

export const Text = styled.p`
  ${background}
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${layout}
  ${letterSpacing}
  ${lineHeight}
  ${position}
  ${space}
  ${typography}
  margin: 0;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  cursor: ${props => props.pointer};
`

// containers

export const Container = styled(Box)({
  width: "1640px",
  minWidth: "1640px",
  margin: "0 auto",
  "@media(max-width: 1440px)": {
    width: "1280px",
    minWidth: "1280px",
  },
  "@media(max-width: 1280px)": {
    width: "976px",
    minWidth: "976px",
  },
  "@media(max-width: 976px)": {
    width: "768px",
    minWidth: "768px",
  },
})

export const FullScreenContainer = styled(Box)(props => ({
  margin: "0 auto",
  width: "100%",
  padding:
    props.customPadding && props.customPadding[0]
      ? `0 ${props.customPadding[0]}px`
      : `0 ${theme.spaces[4]}px`,
  [theme.mediaQueries[0]]: {
    padding:
      props.customPadding && props.customPadding[1]
        ? `0 ${props.customPadding[1]}px`
        : `0 ${theme.spaces[5]}px`,
  },
  [theme.mediaQueries[4]]: {
    padding:
      props.customPadding && props.customPadding[2]
        ? `0 ${props.customPadding[2]}px`
        : `0 ${theme.spaces[6]}px`,
  },
}))

// text

export const BlendText = styled(Text)({
  fontSize: "5.8rem",
  textTransform: "uppercase",
  letterSpacing: "42px",
  fontWeight: "700",
  color: theme.colors.white,
  position: "absolute",
  width: "100%",
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
  zIndex: "9",
  mixBlendMode: "difference",
  "@media (max-width: 1440px)": {
    fontSize: "4.4rem",
    letterSpacing: "36px",
  },
})

export const FixedSideText = styled(Text)({
  display: "none",
  position: "fixed",
  top: "50%",
  right: "-115px",
  transform: "rotate(-90deg)",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  letterSpacing: "1.5px",
  color: theme.colors.white,
  mixBlendMode: "difference",
  zIndex: 8,
  [theme.mediaQueries[1]]: {
    display: "block",
  },
  [theme.mediaQueries[4]]: {
    right: "-84px",
  },
})

export const SectionTitle = styled(Text)({
  fontWeight: "700",
  fontSize: "1rem",
  letterSpacing: "1px",
  m: 0,
  textTransform: "uppercase",
  [theme.mediaQueries[3]]: {
    fontSize: "1.2rem",
    letterSpacing: "1.5px",
  },
})

export const Paragraph = styled(Text)(props => ({
  fontWeight: 300,
  letterSpacing: props.letterSpacing,
  lineHeight:
    props.lineHeight && props.lineHeight[0] ? props.lineHeight[0] : "1.4rem",
  margin: `${theme.spaces[2]}px 0`,
  fontSize: props.fontSize && props.fontSize[0] ? props.fontSize[0] : "0.68rem",
  [theme.mediaQueries[3]]: {
    fontSize:
      props.fontSize && props.fontSize[1] ? props.fontSize[1] : "0.9rem",
    lineHeight:
      props.lineHeight && props.lineHeight[1] ? props.lineHeight[1] : "2rem",
  },
}))

export const SectionParagraph = styled(Paragraph)({
  margin: `${theme.spaces[2]}px 0 ${theme.spaces[4]}px`,
  [theme.mediaQueries[3]]: {
    margin: `${theme.spaces[2]}px 0 72px`,
  },
})

export const VerticalParagraph = styled(Paragraph)({
  writingMode: "vertical-rl",
})

export const AccentText = styled(Box)({
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "0.75rem",
  letterSpacing: "1px",
  textTransform: "uppercase",
  textDecoration: "none",
  [theme.mediaQueries[3]]: {
    fontSize: "1.25rem",
  },
})

export const ButtonRow = styled(Box)({
  "a, p": {
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "0.75rem",
    letterSpacing: "1px",
    color: theme.colors.black,
    textTransform: "uppercase",
    textDecoration: "none",
    [theme.mediaQueries[3]]: {
      fontSize: "0.875rem",
      letterSpacing: "1.5px",
    },
  },
})

export const TextRow = styled(Box)({
  height: "44px",
})

export const SiteName = styled(Link)(props => ({
  fontWeight: 700,
  textDecoration: "none",
  letterSpacing: "1.5px",
  color: props.color || theme.colors.black,
  textTransform: "uppercase",
  fontSize: "1rem",
  [theme.mediaQueries[0]]: {
    fontSize: "1.15rem",
  },
}))

// images

export const ImageWrapper = props => {
  const { imageUrl, styles, showGap } = props
  return (
    <Box
      css={{
        ...styles,
        margin: showGap ? `${theme.spaces[2]}px 0` : 0,
        [theme.mediaQueries[0]]: {
          margin: showGap ? `${theme.spaces[4]}px 0` : 0,
        },
        "&:first-of-type": {
          marginTop: 0,
        },
      }}
    >
      <ImageWrapperInner>
        <Img fluid={imageUrl} />
      </ImageWrapperInner>
    </Box>
  )
}

const ImageWrapperInner = styled(Box)({
  width: "100%",
  position: "relative",
})

// global style sheet

export const GlobalStyles = `
  body {
    margin: 0;
    font-family: "Graphik", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
  }

  body,
  input,
  textarea,
  select,
  button {
    font-family: "Graphik", "Helvetica Neue", sans-serif;
  a {
    text-decoration: none;
    color: ${theme.colors.black};
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .b {
    font-weight: 500;
  }

  button.image-gallery-bullet.customBullet {
    background: #fff;
    opacity: 0.4;
    height: 5px;
    width: 5px;
    padding: 2px;
  }

  button.image-gallery-bullet.customBullet.active {
    background: #fff;
    opacity: 1;
  }
`
