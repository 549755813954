import React from "react"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

import {
  Box,
  FixedSideText,
  GlobalStyles,
} from "../../helpers/styledComponents"
import Footer from "./footer"
import Header from "./header"
import theme from "../../helpers/theme"

const Base = props => {
  const {
    children,
    page,
    hideHeader,
    hideSideText,
    hideFooter,
    background,
  } = props
  return (
    <Box
      m={0}
      pt={hideHeader ? "0px" : theme.header.height}
      background={background}
    >
      <ThemeProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          {!hideHeader && <Header page={page} />}
          <Box width="100%">
            <main>{children}</main>
          </Box>
          {!hideSideText && (
            <FixedSideText>Wildlife &#38; Nature Photographer</FixedSideText>
          )}
          {!hideFooter && <Footer />}
        </Box>
      </ThemeProvider>
    </Box>
  )
}

export default Base
