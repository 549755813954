const breakpoints = ["480px", "768px", "976px", "1280px", "1440px"]

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const mediaQueries = breakpoints.map(bp => `@media (min-width: ${bp})`)

const header = {
  height: ["60px", "80px"],
}

const spaces = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const sizes = [16, 32, 64, 128, 256, 512, 600, 720]

const fontSizes = [8, 10, 12, 14, 18, 24, 32, 36, 48, 60, 72, 84, 100]

const maxWidth = [600, 780, 1300]

const mapHeight = [400, 550]

const responsiveFontSizes = {
  small: [0, 1, 2],
  medium: [2, 3, 5],
  large: [4, 6, 7],
}

const colors = {
  black: "#000000",
  white: "#FFFFFF",
  offWhite: "#F2F4F5",
  maroon: "#83333E",
  blue: "#53AEFF",
  lightGray: "#e8eaea",
  faintGray: "#f5f5f5",
  darkGray: "#111d1d",
}

const fontFamilies = {
  nunitoSans: {
    name: "Nunito Sans",
    weights: [300, 400, 700],
  },
  amiri: {
    name: "Amiri",
    weights: [400, 700],
  },
}

const textStyles = {}

const theme = {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  header,
  mapHeight,
  maxWidth,
  mediaQueries,
  responsiveFontSizes,
  spaces,
  sizes,
  textStyles,
}

export default theme
