import React from "react"
import Helmet from "react-helmet"

const SEO = props => {
  const { title } = props
  let meta = []
  const seo = {
    lang: "en",
    siteName: "Jeremy Hsu",
    metaDescription: "Jeremy Hsu Photography",
  }

  let titleTemplate = "%s"
  if (title) {
    titleTemplate = `%s | ${title}`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: seo.lang,
      }}
      title={seo.siteName}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.metaDescription,
        },
        {
          property: `og:title`,
          content: seo.siteName,
        },
        {
          property: `og:description`,
          content: seo.metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
