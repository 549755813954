import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { Box, FullScreenContainer, Text } from "../../helpers/styledComponents"
import theme from "../../helpers/theme"
import styled from "@emotion/styled-base"

const Footer = props => {
  const data = useStaticQuery(graphql`
    query {
      instagramLogo: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer>
      <Box
        background={theme.colors.black}
        py={[theme.spaces[4]]}
        color={theme.colors.white}
      >
        <FullScreenContainer>
          <Row>
            <Box>
              <FooterText>
                © {new Date().getFullYear()},{` `}
                JEREMY HSU
              </FooterText>
              <FooterText>All Rights Reserved.</FooterText>
            </Box>
            <Box>
              <a
                href="https://www.instagram.com/fujishoe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo>
                  <Img fluid={data.instagramLogo.childImageSharp.fluid} />
                </Logo>
              </a>
            </Box>
          </Row>
        </FullScreenContainer>
      </Box>
    </footer>
  )
}

const Row = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
})

const FooterText = styled(Text)({
  textDecoration: "none",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  fontSize: "0.6rem",
  lineHeight: 2,
  [theme.mediaQueries[3]]: {
    letterSpacing: "1.75px",
    fontSize: "0.75rem",
  },
})

const Logo = styled(Box)({
  height: "25px",
  width: "25px",
  [theme.mediaQueries[3]]: {
    height: "30px",
    width: "30px",
  },
})

export default Footer
